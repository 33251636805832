<template>
  <div class="columns mt-3 is-multiline">
    <div class="column is-12">
      <div class="form-container">
        <label class="label-big font-bold has-text-grey-darker">
          Cantidades
        </label>
        <div class="columns mt-1">
          <div class="column is-3 text-center my-auto">
            <p class="text-lg font-medium mt-3">Persona</p>
          </div>
          <div class="column is-2 text-center my-auto">
            <p class="text-lg font-medium mt-3">Rol</p>
          </div>
          <div class="column is-2 text-center my-auto">
            <p class="text-lg font-medium mt-3">Prelacion</p>
          </div>
          <div class="column is-3 text-center my-auto">
            <p class="text-lg font-medium mt-3">Cantidad</p>
          </div>
          <div class="column is-1 text-center my-auto">
            <p class="text-lg font-medium mt-3">IVA Ordinario</p>
          </div>
          <div class="column is-1 text-center my-auto">
            <p class="text-lg font-medium mt-3">IVA Moratorio</p>
          </div>
        </div>
        <div v-for="persona in personas" :key="persona.persona_id" class="columns mt-1">
          <div class="column is-3 text-center my-auto">
            <p class="text-lg font-medium mt-3">{{ persona.nombre.split('|')[0] }}</p>
          </div>
          <div class="column is-2">
            <valid-select
              disabled
              placeholder="Seleccionar rol..."
              realValue="id"
              show="nombre"
              v-model="persona.rol"
              :data="getRoles"
              :expanded="true"
              @input="validateRoles(persona)"
            />
          </div>
          <div class="column is-2">
            <valid-input
              disabled
              placeholder="Introduzca el orden de prelación..."
              type="number"
              v-model="persona.prelacion"
              :expanded="true"
              :rules="`required|min_value:1|max_value:${personas.length}|repeated_value:${prelacion.join(',')}`"
            />
          </div>
          <div class="column is-3">
            <valid-currency-input
              :rules="`required|currency|max_value:${maxCantidad(persona)}`"
              :placeholder="'Introduzca un valor (max: ' + maxCantidad(persona) + ')...'"
              v-model="persona.cantidad"
              :expanded="true"
              @input="actualizarMontoTotal"
            />
          </div>
          <div class="column is-1 flex items-center justify-center">
            <b-checkbox
              size="is-medium"
              v-model="persona.iva_ordinario"
              true-value="1"
              false-value="0"
            >
            </b-checkbox>
          </div>
          <div class="column is-1 flex items-center justify-center">
            <b-checkbox
              size="is-medium"
              v-model="persona.iva_moratorio"
              true-value="1"
              false-value="0"
            >
            </b-checkbox>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ValidSelect from "@/components/form/ValidSelect";
import ValidInput from "@/components/form/ValidInput";
import ValidCurrencyInput from "@/components/form/ValidCurrencyInput";

import { mapGetters } from "vuex";


export default {
  name: "cantidades_grupo",
  components: {
    ValidCurrencyInput, 
    ValidSelect,
    ValidInput,
  },
  data() {
    return {
      rolesElegidos: new Map(),
    }
  },
  props: {
    personas: Array,
    capital: Number,
    producto_monto: Object
  },
  computed: {
    ...mapGetters("grupoCredito", ["getRoles"]),

    prelacion() {
      return this.personas.filter(persona => persona.prelacion).map(persona => persona.prelacion);
    }
  },
  methods: {
    maxCantidad(persona_actual) {
      const sumaCantidadPersonas = this.personas.reduce((suma, persona) => suma + persona.cantidad, 0);

      return this.capital - (sumaCantidadPersonas - persona_actual.cantidad);
    },
    actualizarMontoTotal() {
      this.producto_monto.monto = this.personas.reduce((montoTotal, persona) => montoTotal + persona.cantidad, 0);
    },
    validateRoles(persona) {
      if (! persona.rol || persona.rol == 4) return;

      if (! this.rolesElegidos.get(persona.rol)) {
        this.rolesElegidos.set(persona.rol, persona.persona_id);
        persona.prelacion = this.getRoles.find(rol => rol.id == persona.rol)?.prelacion;
        return;
      }

      let personaConRolElegido = this.rolesElegidos.get(persona.rol);
      
      if (personaConRolElegido != persona.persona_id) {
        personaConRolElegido = this.personas.findIndex(p => p.persona_id == personaConRolElegido);

        if (personaConRolElegido > -1) {
          this.personas[personaConRolElegido].rol = undefined;
          this.personas[personaConRolElegido].prelacion = undefined;
        }

        for (let [rol, persona_id] of this.rolesElegidos.entries()) {
          if (persona_id == persona.persona_id) {
            this.rolesElegidos.delete(rol);
          }
        }

        this.rolesElegidos.set(persona.rol, persona.persona_id);
        persona.prelacion = this.getRoles.find(rol => rol.id == persona.rol)?.prelacion;
        
      }
    }
  },
  created() {
    this.$store.dispatch('grupoCredito/getRoles');
  }
};
</script>