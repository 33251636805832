<template>
  <ValidationObserver ref="observer" v-slot="{ validate }" class="w-full">
    <div>
      <div class="modal-card">
        <header class="modal-card-head has-background-grey-lighter">
          <p class="modal-card-title has-text-grey-darker text-left">
            Configurar multiples amortizaciones
          </p>
          <button
            type="button"
            class="delete absolute right-0 mr-5"
            @click="$parent.close()"
          />
        </header>
        <section class="modal-card-body has-background-white text-left">
          <ValidInput
            rules="required|min_value:1"
            label="Número de amortizaciones"
            placeholder="1"
            type="number"
            v-model="numero_amortizaciones"
            @input="updateMontoAmortizacion"
          />
          <div v-show="!esCreditoGrupal" class="columns mt-3 mb-1">
            <div class="column flex">
              <b-field class="flex">
                <b-checkbox
                  v-model="capital_creciente"
                  true-value="Si"
                  false-value="No"
                  :disabled="arrendamiento"
                >
                  Capital creciente
                </b-checkbox>
              </b-field>
            </div>
            <div class="column" v-if="capital_creciente == 'No'">
              <ValidCurrencyInput
                ref="currencyInput"
                idinput="monto_amortizacion"
                rules="required|currency"
                label="Monto por amortización"
                placeholder="0.00"
                v-model="monto_amortizacion"
              />
            </div>
          </div>
          <div class="columns" v-if="capital_creciente == 'No'">
            <div class="column">
              <ValidInput
                rules="required|min_value:1"
                label="Cada"
                placeholder="1"
                type="number"
                v-model="numero_tiempo"
              />
            </div>
            <div class="column">
              <ValidSelect
                rules="required"
                label="Tiempo"
                placeholder="Selecciona una opción"
                v-model="tipo_tiempo"
                :expanded="true"
                :data="getTiposTiempo"
                show="nombre"
                realValue="id"
              />
            </div>
          </div>
          <div class="columns" v-if="capital_creciente == 'Si'">
            <div class="column">
              <ValidSelect
                rules="required"
                label="Tiempo"
                placeholder="Selecciona una opción..."
                v-model="tipo_tiempo"
                :expanded="true"
                :data="periodos"
                :disabled="arrendamiento"
                show="nombre"
                realValue="id"
              />
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <valid-datepicker
                rules="required"
                label="Fecha de entrega"
                placeholder="selecciona una fecha..."
                v-model="fecha_inicio"
                position="is-top-right"
              />
            </div>
            <div v-if="arrendamiento" class="column">
              <valid-datepicker
                rules="required"
                label="Fecha primer pago"
                placeholder="selecciona una fecha..."
                v-model="fecha_primer_pago"
                position="is-top-right"
                :min-date="$moment(fecha_inicio).add(1, 'month').toDate()"
              />
            </div>
          </div>
        </section>
        <footer class="modal-card-foot has-background-grey-lighter">
          <button
            class="button is-primary"
            :class="{'is-arrendamiento': arrendamiento}"
            @click="validate().then(result => generate(result))"
          >
            Generar
          </button>
        </footer>
      </div>
    </div>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import ValidInput from "@/components/form/ValidInput";
import ValidSelect from "@/components/form/ValidSelect";
import ValidCurrencyInput from "@/components/form/ValidCurrencyInput";
import ValidDatepicker from "@/components/form/ValidDatepicker";
import DisposicionApi from "@/api/disposicion.api";
import { mapGetters } from "vuex";

export default {
  name: "modalMultiplesAmortizaciones",
  data() {
    return {
      numero_amortizaciones: null,
      numero_tiempo: null,
      tipo_tiempo: null,
      capital_creciente: "No",
      fecha_inicio: this.$fecha_cierre,
      fecha_primer_pago: null,
      monto_amortizacion: null,
      amortizaciones: []
    };
  },
  props: {
    monto: Number,
    periodos: Array,
    productoFinanciero: Number,
    interes_ordinario: Object,
    tipo_pago: String,
    arrendamiento: {
      type: Boolean,
      default: false
    },
    valorResidual: Number,
    enganche: Number,
    tipo_interes_ordinario: Number,
    cantidades_grupo: Array,
    esCreditoGrupal: Boolean,
  },
  components: {
    ValidationObserver,
    ValidCurrencyInput,
    ValidDatepicker,
    ValidInput,
    ValidSelect
  },
  mounted() {
    if (this.arrendamiento || this.esCreditoGrupal) {
      this.capital_creciente = "Si";
    }

    if (this.arrendamiento) {
      this.tipo_tiempo = this.periodos.find(periodo => periodo.nombre == 'mensual (Mismo día calculo a 30 días)')?.id;
    }
  },
  computed: {
    ...mapGetters("catalogos", ["getTiposTiempo", "getProductosFinancieros"]),
    ...mapGetters(["getSofom"]),
    options() {
      return {
        locale: "en",
        currency: "USD"
      };
    }
  },
  methods: {
    /*
      Genera los periodos de pagos dependiendo de lo indicado en los inputs
      @result (Boolean): Resultado de las validaciones
      @index (Integer): posición del arreglo a eliminar
    */
    generate(result) {
      if (!result) {
        return;
      }
      if (this.capital_creciente == "Si") {
        this.loading = true;
        let dias_periodo = this.periodos.find(x => x.id === this.tipo_tiempo)
          .dias;
        let base_intereses = this.getProductosFinancieros.find(
          x => x.id == this.productoFinanciero
        ).base_intereses_id;
        let data = {
          producto_financiero_id: this.productoFinanciero,
          withIva: this.interes_ordinario.iva,
          fecha_entrega: this.$moment(this.fecha_inicio).format("YYYY-MM-DD"),
          capital: this.arrendamiento ? this.monto - this.enganche : this.monto,
          ivaCapital: this.arrendamiento ? 1 : 0,
          infoPagos: {
            cantidadDePeriodos: this.numero_amortizaciones,
            diasPorPeriodo: dias_periodo,
            mesForzado: this.tipo_tiempo == this.periodos.find(periodo => periodo.nombre == 'mensual (Mismo día calculo a 30 días)')?.id,
            mismoDia: ['mensual (Mismo día calculo variable)', 'mensual (Mismo día calculo a 30 días)'].includes(this.periodos.find(periodo => periodo.id == this.tipo_tiempo)?.nombre)
          },
          tipoPago: "regulares",
          esCapitalCreciente: this.capital_creciente,
          interesOrdinario: this.interes_ordinario.tasa,
          tasaBaseId: this.interes_ordinario.tasa_base,
          BaseParaInteresId: base_intereses,
          tratamientoIntereses: this.arrendamiento ? 2 : 3,
          fechaCorteIsAniversario: this.interes_ordinario.fecha_corte,
          pagoIsDiaHabilAnterior: this.interes_ordinario.dia_inhabil,
          calculoSobreSaldos: this.tipo_interes_ordinario,
        };
        
        if (this.arrendamiento) {
          data.arrendamiento = { valor_residual: this.monto * (this.valorResidual / 100) }
        }

        if (this.fecha_primer_pago && this.periodos.find(periodo => periodo.id == this.tipo_tiempo)?.nombre == 'mensual (Mismo día calculo a 30 días)') {
          data.fecha_primer_pago = this.$moment(this.fecha_primer_pago).format("YYYY-MM-DD");
        }

        if (this.esCreditoGrupal) {
          let sumaCapitalDisposicionesConIva = 0;

          this.cantidades_grupo.forEach(async (persona) => {
            if (persona.cantidad <= 0) return;

            if (persona.iva_ordinario) {
              sumaCapitalDisposicionesConIva += persona.cantidad;
            }

            let dataCopy = {...data};
            dataCopy.capital = persona.cantidad;

            persona.tabla_irregular = { data: [] };

            let simulacion = await DisposicionApi.simularTablaAmortizacion(dataCopy);
            
            let total = 0;

            simulacion.data.tablaAmortizacion.forEach(amortizacion => {
              let abono_capital = parseFloat(amortizacion.capitalPagoDelPeriodo);

              persona.tabla_irregular.data.push({
                fecha_pago: this.$moment(amortizacion.fechaDePago).format("YYYY-MM-DD"),
                abono_capital: abono_capital
              });

              total += parseFloat(amortizacion.capitalPagoDelPeriodo);
            });
            
            let restante = dataCopy.capital - total;
            persona.tabla_irregular.data[
              persona.tabla_irregular.data.length - 1
            ].abono_capital += restante;
          });

          data.grupal = 1;
          data.capital_disposiciones_con_iva = sumaCapitalDisposicionesConIva;
        }

        DisposicionApi.simularTablaAmortizacion(
          data,
          result => {
            return result.data;
          },
          error => {
            return error;
          }
        ).then(data => {
          this.regresarAmortizaciones(data["tablaAmortizacion"]);
        });
      } else {
        this.calcularAmortizaciones();
      }
    },
    /*
      En caso de ser capital creciente,
      Agrega al array de amortizaciones lo regresado de la API para despues enviarlo a su parent
      @amortizaciones (Array): Amortizaciones calculadas desde API
    */
    regresarAmortizaciones(amortizaciones) {
      let total = 0;
      amortizaciones.forEach(amortizacion => {
        let abono_capital = parseFloat(amortizacion.capitalPagoDelPeriodo);

        this.amortizaciones.push({
          fecha_pago: this.$moment(amortizacion.fechaDePago).toDate(),
          abono_capital: abono_capital
        });

        total += parseFloat(amortizacion.capitalPagoDelPeriodo);
      });

      if (this.arrendamiento) {
        this.amortizaciones[
          this.amortizaciones.length - 1
        ].restante = this.monto - total;

        this.$emit(
          "fecha_primer_pago",
          this.$moment(this.fecha_primer_pago).format("YYYY-MM-DD")
        );
      }

      this.$emit("amortizaciones", this.amortizaciones);
      this.$emit(
        "fecha_inicio",
        this.$moment(this.fecha_inicio).format("YYYY-MM-DD")
      );

      const esMesForzado = this.tipo_tiempo == this.periodos.find(periodo => periodo.nombre == 'mensual (Mismo día calculo a 30 días)')?.id || this.arrendamiento;
      const esMismoDia = ['mensual (Mismo día calculo variable)', 'mensual (Mismo día calculo a 30 días)'].includes(this.periodos.find(periodo => periodo.id == this.tipo_tiempo)?.nombre);

      this.$emit("isregular", {
        isRegular: true,
        numero_tiempo: this.numero_amortizaciones,
        periodo: this.tipo_tiempo,
        mesForzado: esMesForzado || null,
        mismoDia: esMismoDia
      });
      this.$parent.close();
    },
    /*
      En caso de no ser capital creciente, 
      se calculan las amortizaciones en base a los inputs y se envia a su parent
    */
    calcularAmortizaciones() {
      let fecha = this.$moment(this.fecha_inicio)
          .add(this.numero_tiempo, this.tipo_tiempo)
          .toDate();
      let monto_total = this.monto;
      for (let i = 0; i < this.numero_amortizaciones; i++) {
        let abono_capital = Math.floor(
          parseFloat(this.monto_amortizacion.toFixed(2))
        );
        monto_total -= Math.floor(parseFloat(abono_capital.toFixed(2)));
        if (this.numero_amortizaciones - 1 === i) {
          if (monto_total > 0) {
            abono_capital += monto_total;
          }
        }
        this.amortizaciones.push({
          fecha_pago: this.$moment(fecha).toDate(),
          abono_capital: abono_capital
        });
        fecha = this.$moment(fecha)
          .add(this.numero_tiempo, this.tipo_tiempo)
          .toDate();
      }
      this.$emit("amortizaciones", this.amortizaciones);
      this.$emit(
        "fecha_inicio",
        this.$moment(this.fecha_inicio).format("YYYY-MM-DD")
      );
      this.$emit("isregular", false);
      this.$parent.close();
    },
    updateMontoAmortizacion() {
      this.monto_amortizacion =
        this.numero_amortizaciones <= 0
          ? 0
          : Math.floor(
              (parseFloat(this.monto) / this.numero_amortizaciones) * 100
            ) / 100;
    }
  }
};
</script>
