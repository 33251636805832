<template>
  <div>
    <div
      class="form-container has-background-primary-04 has-border-radius mt-3"
      :class="{ 
        'has-background-pasivo-04': pasivo,
        'has-background-arrendamiento-light': esArrendamiento 
      }"
    >
      <div class="columns is-mobile">
        <div class="column is-9 is-11-tablet">
          <label class="text-3xl font-bold has-text-grey-darker mb-2"
            >Producto y monto</label
          >
        </div>
        <div class="column is-3 is-1-tablet text-right">
          <b-button
            type="is-text"
            @click="editResumen(0)"
            :class="
              pasivo 
                ? 'has-text-pasivo' 
                : esArrendamiento ? 'has-text-arrendamiento' : 'has-text-primary'
            "
            >Editar</b-button
          >
        </div>
      </div>
      <div
        class="columns is-multiline"
        v-if="
          getProductosFinancieros.length > 0 &&
            getMonedas.length > 0 &&
            getDestinoRecursos
        "
      >
        <div class="column is-3">
          <label class="label fw4">Tipo de producto</label>
          <p>
            {{
              getProductosFinancieros.find(
                x => x.id === producto_monto.producto_financiero
              ).alias
            }}
          </p>
        </div>
        <div class="column is-3">
          <label class="label fw4">Monto</label>
          <p>{{ producto_monto.monto | currency }}</p>
        </div>
        <div class="column is-3">
          <label class="label fw4">Moneda</label>
          <p>
            {{ monedaClave }}
          </p>
        </div>
        <div
          class="column is-3"
          v-if="$route.params.linea_credito_id && linea.destino_recursos"
        >
          <label class="label fw4">Destino de los recursos</label>
          <p>
            {{
              getDestinoRecursos.find(x => x.id == linea.destino_recursos)
                .nombre
            }}
          </p>
        </div>
        <div class="column is-3" v-if="$route.params.linea_credito_id">
          <label class="label fw4">Línea de crédito</label>
          <preview 
            :id="linea.id" 
            tipo="linea" 
            :color="esArrendamiento ? 'has-text-arrendamiento-always' : 'has-text-info' "
            :border="esArrendamiento ? 'has-border-arrendamiento' : 'has-border-info'"
          >
            {{ linea.id }}
          </preview>
        </div>
        <div class="column is-3" v-if="acreditado">
          <label class="label fw4">Acreditado</label>
          <preview 
            :id="linea.persona_id" 
            tipo="persona"
            :color="esArrendamiento ? 'has-text-arrendamiento-always' : 'has-text-info' "
            :border="esArrendamiento ? 'has-border-arrendamiento' : 'has-border-info'"
          >
            {{ acreditado.alias }}
          </preview>
        </div>
      </div>
    </div>
    <div
      class="form-container has-background-primary-04 has-border-radius mt-3"
      :class="{ 
        'has-background-pasivo-04': pasivo,
        'has-background-arrendamiento-light': esArrendamiento 
      }"
    >
      <div class="columns is-mobile">
        <div class="column is-9 is-11-tablet">
          <label class="text-3xl font-bold has-text-grey-darker mb-2"
            >Interés ordinario</label
          >
        </div>
        <div class="column is-3 is-1-tablet text-right">
          <b-button
            type="is-text"
            @click="editResumen(1)"
            :class="
              pasivo 
                ? 'has-text-pasivo' 
                : esArrendamiento ? 'has-text-arrendamiento' : 'has-text-primary'
            "
            >Editar</b-button
          >
        </div>
      </div>
      <div class="columns is-multiline" v-if="getTasasBase.length > 0">
        <div class="column is-3">
          <label for class="label fw4">Tasa base</label>
          <p>
            {{
              getTasasBase.find(x => x.id === interes_ordinario.tasa_base)
                .nombre
            }}
          </p>
        </div>
        <div class="column is-3">
          <label for class="label fw4">Tasa</label>
          <p>
            {{
              interes_ordinario.tasa
                ? interes_ordinario.tasa + "%"
                : "No definida"
            }}
          </p>
        </div>
        <div class="column is-3">
          <label for class="label fw4">Tasa variable en día inhábil</label>
          <p>{{ interes_ordinario.dia_inhabil ? "Sí" : "No" }}</p>
        </div>
        <div class="column is-3">
          <label for class="label fw4">Tipo de Cobro</label>
          <p>
            {{
              getTratamientoIntereses.find(
                x => x.id == interes_ordinario.tipo_cobro
              ).tipo
            }}
          </p>
        </div>
        <div class="column is-3">
          <label for class="label fw4">Fecha de corte</label>
          <p>
            {{
              getFechasCorte.find(x => x.id == interes_ordinario.fecha_corte)
                .nombre
            }}
          </p>
        </div>
        <div class="column is-3">
          <label for class="label fw4">Forma de cálculo</label>
          <p>
            {{
              getFormasCalculo.find(
                x => x.id == interes_ordinario.forma_calculo
              ).nombre
            }}
          </p>
        </div>
        <div class="column is-3">
          <label for class="label fw4">IVA</label>
          <p>{{ interes_ordinario.iva ? "Sí" : "No" }}</p>
        </div>
        <div class="column is-3">
          <div class="flex">
            <label class="label fw4 mr-1">CAT</label>
            <b-tooltip
              type="is-dark"
              :label="`Se calcula al inicio ${esArrendamiento ? 'del arrendamiento' : 'de la disposición'} y no vuelve a cambiar.`"
            >
              <b-icon
                class="has-text-primary has-background-primary-02 rounded-full"
                :class="{ 'has-text-arrendamiento has-background-arrendamiento-02': esArrendamiento }"
                icon="exclamation-circle"
              />
            </b-tooltip>
          </div>
          <p>{{ cat }}%</p>
        </div>
        <div v-if="interes_ordinario.provision_anticipada" class="column is-3">
          <label for class="label fw4">Dias antes de anticipación</label>
          <p>{{ interes_ordinario.provision_anticipada }}</p>
        </div>
      </div>
    </div>
    <div
      class="form-container has-background-primary-04 has-border-radius mt-3"
      :class="{ 
        'has-background-pasivo-04': pasivo,
        'has-background-arrendamiento-light': esArrendamiento 
      }"
    >
      <div class="columns is-mobile">
        <div class="column is-9 is-11-tablet">
          <label class="text-3xl font-bold has-text-grey-darker mb-2"
            >Interés moratorio</label
          >
        </div>
        <div class="column is-3 is-1-tablet text-right">
          <b-button
            type="is-text"
            @click="editResumen(2)"
            :class="
              pasivo 
                ? 'has-text-pasivo' 
                : esArrendamiento ? 'has-text-arrendamiento' : 'has-text-primary'
            "
            >Editar</b-button
          >
        </div>
      </div>
      <div class="columns is-multiline">
        <div class="column is-3">
          <label for class="label fw4">Tipo de interés</label>
          <p>
            {{
              getFormasCalculoMoratorio.find(
                x => x.id == interes_moratorio.tipo
              ).nombre
            }}
          </p>
        </div>
        <div class="column is-3" v-if="interes_moratorio.tipo == 0">
          <label class="label fw4">Tasa base</label>
          <p>
            {{
              getTasasBase.find(x => x.id == interes_moratorio.tasa_base).nombre
            }}
          </p>
        </div>
        <div class="column is-3">
          <label class="label fw4">Tasa</label>
          <p v-if="interes_moratorio.tipo == 0">
            {{
              interes_moratorio.tasa
                ? interes_moratorio.tasa + "%"
                : "No definida"
            }}
          </p>
          <p v-else>
            {{
              (interes_ordinario.tasa * interes_moratorio.veces).toFixed(2) +
                "%"
            }}
          </p>
        </div>
        <div class="column is-3">
          <label for class="label fw4">IVA</label>
          <p>{{ interes_moratorio.iva ? "Sí" : "No" }}</p>
        </div>
      </div>
    </div>
    <div
      class="form-container has-background-primary-04 has-border-radius mt-3"
      :class="{ 
        'has-background-pasivo-04': pasivo,
        'has-background-arrendamiento-light': esArrendamiento 
      }"
    >
      <div class="columns is-mobile">
        <div class="column is-9 is-11-tablet">
          <label class="text-3xl font-bold has-text-grey-darker mb-2"
            >Tratamiento de capital</label
          >
        </div>
        <div class="column is-3 is-1-tablet text-right">
          <b-button
            type="is-text"
            @click="editResumen(3)"
            :class="
              pasivo 
                ? 'has-text-pasivo' 
                : esArrendamiento ? 'has-text-arrendamiento' : 'has-text-primary'
            "
            >Editar</b-button
          >
        </div>
      </div>
      <div class="columns" v-if="getTiposPago.length > 0">
        <div class="column is-3">
          <label for class="label fw4">Tipo de cobro</label>
          <p>
            {{
              getTiposPago.find(x => x.clave === tratamiento_capital.tipo_pago)
                .alias
            }}
          </p>
        </div>
        <div
          class="column is-3"
          v-if="tratamiento_capital.tipo_pago == 'vencimiento'"
        >
          <label for class="label fw4">Días</label>
          <p>{{ tratamiento_capital.plazo_dias }}</p>
        </div>
        <div
          class="column is-3"
          v-if="tratamiento_capital.tipo_pago == 'vencimiento'"
        >
          <label for class="label fw4">Fecha de entrega</label>
          <p>
            {{ tratamiento_capital.fecha_inicio | moment("DD-MM-YYYY") }}
          </p>
        </div>
        <div
          class="column is-3"
          v-if="tratamiento_capital.tipo_pago == 'vencimiento'"
        >
          <label for class="label fw4">Fecha de vencimiento</label>
          <p>
            {{ tratamiento_capital.fecha_vencimiento | moment("DD-MM-YYYY") }}
          </p>
        </div>
        <!-- <div
          class="column is-3"
          v-if="tratamiento_capital.tipo_pago == 'regulares'"
        >
          <label for="" class="label fw4">Periodos</label>
          <p>
            {{ tratamiento_capital.numero_tiempo }}
            de manera
            {{
              getPeriodosRegulares.find(
                x => x.id == tratamiento_capital.periodo
              ).nombre
            }}
          </p>
        </div>-->
        <div
          class="column is-3"
          v-if="tratamiento_capital.tipo_pago == 'irregulares'"
        >
          <label for class="label fw4">Número de periodos</label>
          <p>{{ 
            tratamiento_capital.isRegular 
              ? tratamiento_capital.tabla_irregular.data.length - 1 
              : tratamiento_capital.tabla_irregular.data.length 
          }}</p>
        </div>
      </div>
    </div>
    <div
      class="form-container has-background-primary-04 has-border-radius mt-3"
      :class="{ 
        'has-background-pasivo-04': pasivo,
        'has-background-arrendamiento-light': esArrendamiento 
      }"
    >
      <div class="columns is-mobile">
        <div class="column is-9 is-11-tablet">
          <label class="text-3xl font-bold has-text-grey-darker mb-2"
            >Accesorios</label
          >
        </div>
        <div class="column is-3 is-1-tablet text-right">
          <b-button
            type="is-text"
            @click="editResumen(4)"
            :class="
              pasivo 
                ? 'has-text-pasivo' 
                : esArrendamiento ? 'has-text-arrendamiento' : 'has-text-primary'
            "
            >Editar</b-button
          >
        </div>
      </div>
      <p class="ml-2" v-if="accesorios.detalles.length == 0">
        No hay accesorios declarados para {{ esArrendamiento ? 'este arrendamiento' : 'esta disposición' }}
      </p>
      <b-table
        v-else
        :data="accesorios.detalles"
        class="has-border-radius mb-6"
        :class="{
          'has-background-pasivo-04': pasivo,
          'has-background-primary-04': !pasivo
        }"
      >
        <b-table-column field="accesorio" label="ACCESORIO" v-slot="props">
          {{
            getCostosExtras.find(x => x.id == props.row.nombre)
              ? getCostosExtras.find(x => x.id == props.row.nombre).nombre
              : ""
          }}
        </b-table-column>
        <b-table-column field="valor" label="VALOR" v-slot="props">
          <span
            v-if="
              $route.name == 'editar_disposicion' && props.row.tipo == 'Monto'
            "
            >{{ props.row.monto | currency | hp }}</span
          >
          <span v-else>
            {{ props.row.monto }}{{ props.row.tipo == "Porcentaje" ? "%" : "" }}
          </span>
          <span v-if="props.row.monto_porcentaje"
            >({{ props.row.monto_porcentaje | currency | hp }})</span
          >
        </b-table-column>
        <b-table-column
          field="fecha_vencimiento"
          label="FECHA VENCIMIENTO"
          v-slot="props"
        >
          {{ props.row.fecha_vencimiento | moment("DD-MM-YYYY") }}
        </b-table-column>
        <b-table-column field="iva" label="IVA" v-slot="props">
          {{ calcularIva(props.row) | currency | hp }}
        </b-table-column>
      </b-table>
    </div>
    <div
      class="form-container has-border-radius mt-3"
      v-if="tabla_amortizacion != null"
    >
      <div class="columns">
        <div class="column">
          <tabla-amortizacion
            :arrendamiento="esArrendamiento"
            :data="tabla_amortizacion"
            title-class="has-text-grey-darker text-3xl font-bold"
            table-class="mt-2 text-sm"
            :tasa-base="tasaBase"
            :datosParaImprimir="datosParaImprimirTabla"
            :sinIvaCapitalizado="interes_ordinario.tipo_cobro != 1"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PersonasApi from "@/api/personas.api";
import { mapGetters } from "vuex";
import TablaAmortizacion from "@/components/Disposicion/Detalle/TablaAmortizacion";

export default {
  name: "Resumen",
  components: {
    TablaAmortizacion
  },
  data() {
    return {
      tabla_amortizacion: null,
      cat: null,
      acreditado: null,
    };
  },
  props: {
    cantidades_grupo: Object,
    pasivo: Boolean,
    producto_monto: Object,
    tratamiento_capital: Object,
    interes_ordinario: Object,
    interes_moratorio: Object,
    accesorios: Object,
    linea: Object,
    esCreditoGrupal: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    editResumen(tipo) {
      this.$emit("editResumen", tipo);
    },
    calcularIva(row) {
      if (row.tipo == "Porcentaje")
        return row.iva
          ? row.monto_porcentaje * this.getMe.sucursal_activa.iva
          : 0;
      else return row.iva ? row.monto * this.getMe.sucursal_activa.iva : 0;
    }
  },
  computed: {
    ...mapGetters("catalogos", [
      "getProductosFinancieros",
      "getMonedas",
      "getTiposPago",
      "getPeriodosRegulares",
      "getTasasBase",
      "getCostosExtras",
      "getTratamientoIntereses",
      "getFechasCorte",
      "getFormasCalculo",
      "getFormasCalculoMoratorio",
      "getDestinoRecursos"
    ]),
    ...mapGetters(["getMe", "getSofom"]),
    tasaMoratorio() {
      const EN_BASE_A_ORDINARIO = 1;
      return this.interes_moratorio.tipo != EN_BASE_A_ORDINARIO
        ? this.interes_moratorio.tasa
        : this.interes_ordinario.tasa * this.interes_moratorio.veces;
    },
    monedaClave() {
      return this.getMonedas.find(x => x.id == this.producto_monto.moneda)
        .nombre;
    },
    tasaBase() {
      return this.getTasasBase.find(
        x => x.id === this.interes_ordinario.tasa_base
      ).nombre;
    },
    sumaAccesorios() {
      let suma = 0;
      if (this.accesorios.detalles.length >= 1) {
        this.accesorios.detalles.forEach(accesorio => {
          accesorio.monto_porcentaje
            ? (suma += accesorio.monto_porcentaje)
            : (suma += accesorio.monto);
        });
      }
      return suma;
    },
    datosParaImprimirTabla() {
      return {
        tabla_amortizacion: this.tabla_amortizacion,
        tipo_tasa: this.tasaBase == "Tasa Fija" ? "Fija" : "Variable",
        tasa_nombre: this.tasaBase,
        tasa_cantidad: `${this.interes_ordinario.tasa}%`,
        tasa_moratorio: `${this.tasaMoratorio}%`,
        producto_financiero_alias: this.producto_monto.producto_objeto.alias,
        moneda_clave: this.monedaClave,
        disposicion_capital: this.producto_monto.monto,
        fecha_vencimiento: this.$moment(
          this.tratamiento_capital.fecha_vencimiento
        ).format("DD-MM-YYYY"),
        linea_credito_id: this.linea.id
      };
    },
    esArrendamiento() {
      return this.producto_monto.producto_objeto ? this.producto_monto?.producto_objeto?.tipo_credito.tipo.includes("Arrendamiento") : false;
    }
  },
  async mounted() {
    
    if (this.linea.persona_id && !this.esCreditoGrupal) {
      const getAcreditado = await PersonasApi.getDetallesPersona(this.linea.persona_id);
      this.acreditado = getAcreditado && getAcreditado.data;
    }

    if (this.getDestinoRecursos == null) {
      await this.$store.dispatch("catalogos/getDestinoRecursos");
    }
    if (this.getTratamientoIntereses == null) {
      await this.$store.dispatch("catalogos/getTratamientoIntereses");
    }
    let infoPagos = {};
    switch (this.tratamiento_capital.tipo_pago) {
      case "vencimiento":
        infoPagos = [
          {
            fecha: this.$moment(
              this.tratamiento_capital.fecha_vencimiento
            ).format("YYYY-MM-DD"),
            capital: this.producto_monto.monto
          }
        ];
        break;
      case "irregulares":
        if (this.tratamiento_capital.isRegular || this.esArrendamiento) {
          infoPagos = {
            cantidadDePeriodos: this.tratamiento_capital.numero_tiempo,
            diasPorPeriodo: this.getPeriodosRegulares.find(
              periodo => periodo.id == this.tratamiento_capital.periodo
            ).dias,
            mesForzado: this.tratamiento_capital.mesForzado,
            mismoDia: this.tratamiento_capital.mismoDia
          };
        } else {
          infoPagos = this.tratamiento_capital.tabla_irregular.data.map(
            pago => {
              return {
                fecha: this.$moment(pago.fecha_pago).format("YYYY-MM-DD"),
                capital: pago.abono_capital
              };
            }
          );
        }
        break;
    }
    let producto_financiero = this.getProductosFinancieros.find(
      prod => prod.id === this.producto_monto.producto_financiero
    );
    let data = {
      capital: this.esArrendamiento ? this.producto_monto.monto - this.producto_monto.enganche : this.producto_monto.monto,
      fecha_entrega:
        this.$moment(this.tratamiento_capital.fecha_inicio).format(
          "YYYY-MM-DD"
        ) || this.getSofom.fecha_cierre,
      infoPagos: infoPagos, 
      tipoPago: this.tratamiento_capital.isRegular || this.esArrendamiento
        ? "regulares"
        : this.tratamiento_capital.tipo_pago,
      interesOrdinario: this.interes_ordinario.tasa,
      tasaBaseId: this.interes_ordinario.tasa_base,
      BaseParaInteresId: producto_financiero.base_intereses_id,
      tratamientoIntereses: this.interes_ordinario.tipo_cobro,
      fechaCorteIsAniversario: this.interes_ordinario.fecha_corte,
      pagoIsDiaHabilAnterior: this.interes_ordinario.dia_inhabil,
      withIva: this.interes_ordinario.iva,
      sumaExtras: this.sumaAccesorios,
      calculoSobreSaldos: this.producto_monto.producto_objeto.tipo_interesordinario_id,
      ivaCapital:
        this.producto_monto?.producto_objeto?.tipo_credito.tipo.includes('Arrendamiento') ? 1 : 0
    };
    if (this.esArrendamiento) {
          data.arrendamiento = { valor_residual: this.producto_monto.monto * (this.producto_monto.valor_residual / 100) };
          data.fecha_primer_pago = this.$moment(this.tratamiento_capital.fecha_primer_pago).format("YYYY-MM-DD");
    }
    if (this.esCreditoGrupal) {
      data.grupal = 1;

      data.capital_disposiciones_con_iva = this.cantidades_grupo.reduce((acc, curr) => {
        if (curr.iva_ordinario) {
          return acc += curr.cantidad;
        }

        return acc;
      }, 0);
    }
    await this.$store
      .dispatch("disposicion/simularTablaAmortizacion", data)
      .then(data => {
        this.tabla_amortizacion = data["tablaAmortizacion"];
        this.cat = parseFloat(data["cat"]).toFixed(2);
      });
  }
};
</script>
